<template>
  <b-card
    id="device-qr-printer"
    bg-variant="white"
    style="height: 23cm;line-height: unset !important;"
  >
    <p style="text-align: center;">
      <b-img
        center
        width="280px"
        height="124px"
        :src="require('@/assets/images/logo/ETLogoBlack.png')"
      />
    </p>
    <p
      :key="'a'+item.assetID+item.deviceID"
      style="text-align: center;font-size: 2.5rem;margin: 0px;font-weight: bold;font-family: Arial; color: #000;line-height: unset !important;"
    >
      Asset ID {{ item.assetID ? item.assetID : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}
    </p>
    <p
      :key="'d'+item.assetID+item.deviceID"
      style="text-align: center;font-size: 2.5rem;font-weight: bold;margin: 0px;font-family: Arial;color: #000;line-height: unset !important;"
    >
      Device ID {{ item.deviceID }}
    </p>
    <p
      :key="'s'+item.assetID+item.deviceID"
      style="text-align: center;font-size: 2.5rem;font-weight: bold;margin: 0px;font-family: Arial;color: #000;line-height: unset !important;"
    >
      Spec F-{{ item.platform ? item.platform[0] == 'P'? 'RO':'RA' :'RA' }}_{{ item.modem?item.modem[0]:' ' }}_{{ item.sensorType?item.sensorType[0]+item.sensorType[1]+item.sensorType[2]:'   ' }}_{{ item.carrier ? item.carrier.includes('M1')?'M1':item.carrier.includes('Singtel')?'ST':item.carrier.includes('StarHub')?'SH':item.carrier[0]+item.carrier[1]:'  ' }}
    </p>
    <p style="text-align: center;">
      <qrcode-vue
        :value="item.deviceID"
        :scale="printImageQuality"
        :size="230"
        level="H"
        render-as="svg"
        style="text-align: center;"
      />
    </p>
    <p style="text-align: center;font-size: 1.75rem; font-weight: bold;margin: 0px; font-family: Arial;color: #000;line-height: unset !important;">
      Property of Elevate Tech Pte Ltd.
    </p>
    <p style="text-align: center;font-size: 1.75rem; font-weight: bold;margin: 0px;font-family: Arial;color: #000;line-height: unset !important;">
      Please do NOT remove or turn off
    </p>
    <p style="text-align: center;font-size: 1.75rem;font-weight: bold;margin: 0px;font-family: Arial;color: #000;line-height: unset !important;">
      the power supply to the Sensor box.
    </p>
    <p style="text-align: center;font-size: 1.75rem;font-weight: bold;margin: 0px;font-family: Arial;color: #000;line-height: unset !important;">
      You can contact us at contact@elevate-sg.com
    </p>
    <p style="text-align: center;font-size: 1.75rem;margin: 0px;font-family: Arial;color: #000;line-height: 1 !important;">
      24 Peck Seah Street #05-07
    </p>
    <p style="text-align: center;font-size: 1.75rem;margin: 0px;font-family: Arial;color: #000;line-height: 1 !important;">
      Nehsons Building Singapore 079314
    </p>
  </b-card>
</template>

<script>
import {
  BImg, BCard,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import QrcodeVue from 'qrcode.vue'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    QrcodeVue,
    BImg,
    BCard,
  },
  setup() {
    return {
      printImageQuality: 200,
    }
  },
})
</script>
