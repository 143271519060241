<template>
  <!-- <b-overlay
    id="overlay-background"
    :show="showOverlay"
    :variant="'light'"
    :opacity="0.85"
    :blur="'2px'"
    rounded="lg"
  > -->
  <b-card no-body>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-spinner
        v-if="showOverlay"
        class="mr-1 mt-2"
        label="Small Spinner"
        variant="primary"
      />
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            size="lg"
            placeholder="Type to Search"
            type="text"
            class="d-inline-block mt-2 mr-2"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      v-resize="setDimensions"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :fixed-header="true"
      :max-height="maxHeight"
      style-class="vgt-table striped"
      class="table b-table b-table-no-border-collapse"
      he
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'deviceID'">
          <feather-icon
            class="mr-1"
            :class="connectedVariant(props.row.lastUpdatedTime) ? 'text-success' : 'text-danger' "
            :fill="connectedVariant(props.row.lastUpdatedTime) ? '#28c76f' : '#ea5455' "
            icon="CircleIcon"
            size="20"
          />{{ props.row.deviceID }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            :variant="statusVariant(props.row.status)"
            class="text-capitalize"
          >
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'network'">
          <b-icon
            v-if="isWifi(props.row.connectionMode)"
            icon="wifi"
            font-scale="1.1"
            variant="success"
            class="ml-1"
          />
          <span
            v-if="isLTE(props.row.connectionMode)"
          >
            <b-icon
              icon="reception-4"
              font-scale="1.1"
              variant="success"
            />
            {{ props.row.network ? props.row.network: '' }}
          </span>
        </span>

        <!-- Column: swVersion -->
        <span v-else-if="props.column.field === 'swVersion'">
          <span>
            {{ props.row.swVersion.toFixed(1) }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.status == 'untested' || props.row.status == 'returned'"
                class="choose-button"
                @click="updateStatus(props.row)"
              >
                <feather-icon
                  icon="ActivityIcon"
                  class="mr-50"
                />
                <span>Tested</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <feather-icon
                  icon="WifiIcon"
                  class="mr-50"
                />
                <span>Connect</span>
              </b-dropdown-item> -->
              <b-dropdown-item
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-lg
                @click="selectedItem = props.row"
              >
                <feather-icon
                  icon="PrinterIcon"
                  class="mr-50"
                />
                <span>Print QR</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <b-modal
      id="modal-lg"
      ok-title="Print"
      title="Print QR Code"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      ok-variant="primary"
      centered
      size="lg"
      @show="loadModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <DeviceQRPrinter
        :item="selectedItem"
      />
    </b-modal>
  </b-card>
  <!-- </b-overlay> -->
</template>

<script>
import {
  BCard, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BSpinner, BModal, VBModal, BIcon, BIconWifi, BIconReception4,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate, isConnected } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import deviceStoreModule from '../deviceStoreModule'
import DeviceQRPrinter from './DeviceQRPrinter.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BModal,
    DeviceQRPrinter,
    BIcon,
    BIconWifi,
    BIconReception4,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-device'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, deviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      selectedItem: '',
      maxHeight: '82vh',
      showOverlay: true,
      timer: null,
      pageLength: 3,
      dir: false,
      columns: [
        { field: 'deviceID', sortable: true, label: 'Device' },
        { field: 'assetID', sortable: true, label: 'Asset', type: 'number' },
        { field: 'status', sortable: true, label: 'Status'},
        { field: 'sensorType', sortable: true, label: 'Sensor' },
        { field: 'modem', sortable: true, label: 'Modem' },
        { field: 'swVersion', sortable: true, label: 'Version', type: 'decimal' },
        { field: 'IMEI', sortable: true, label: 'IMEI', type: 'number' },
        { field: 'networkSignal', sortable: true, label: 'Signal', type: 'number' },
        { field: 'carrier', sortable: true, label: 'Carrier' },
        { field: 'network', sortable: false, label: 'Network' },
        { field: 'CPUTemp', sortable: true, label: 'CPUTemp' },
        {
          field: 'lastUpdatedTime',
          sortable: true,
          label: 'Last Reported',
          type: 'date',
          formatFn: msEpochToDate,
        },
        {
          field: 'provisionedTime',
          sortable: true,
          label: 'Created',
          type: 'date',
          formatFn: msEpochToDate,
        },
        {
          field: 'updatedAt',
          sortable: true,
          label: 'Last Updated',
          type: 'date',
          formatFn: msEpochToDate,
        },
        { field: 'updatedBy', sortable: true, label: 'By' },
        { field: 'action', sortable: false, label: 'Action' },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Assigned',
        2: 'Tested',
        3: 'Untested',
        4: 'Returned',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned      : 'light-primary',
        tested : 'light-success',
        untested     : 'light-danger',
        returned     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    connectedVariant() {
      return isConnected
    },
    formatFn(value) {
      // format with 1 decimal place
      console.log(value)
      return value.toFixed(1)
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.fetchDevices()
    this.timer = window.setInterval(() => {
      setTimeout(this.fetchDevices(), 0)
    }, 1000 * 10)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      window.clearInterval(this.timer)
      clearTimeout(this.timer)
    }
  },
  methods: {
    fetchDevices() {
      this.showOverlay = true
      store
        .dispatch('app-device/fetchDevices')
        .then(response => {
          this.rows = response.data
          this.showOverlay = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateStatus(device) {
      // eslint-disable-next-line no-param-reassign
      device.status = 'tested'
      store
        .dispatch('app-device/updateDevice', device)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.result,
              icon: 'CheckIcon',
              variant: 'Success',
            },
          })
          this.fetchDevices()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Updating device',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resetModal() {
      this.selectedItem = null
    },
    loadModal() {
      if (this.selectedItem) {
        return true
      }
      return false
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      // printThis(document.getElementById('device-qr-printer'))
      const printWindow = window.open('', 'PRINT')
      printWindow.document.write('<html><head>')
      printWindow.document.write('<style>@media print {body { transform: rotate(-270deg); }}</style>')
      printWindow.document.write('</head><body >')
      printWindow.document.write('<body onload="printThisPage()" style="background-color: white;">')
      // eslint-disable-next-line prefer-template
      printWindow.document.write(document.getElementById('device-qr-printer').innerHTML)
      printWindow.document.write('</body></html>')
      printWindow.document.addEventListener('DOMContentLoaded', () => {
        printWindow.document.close() // necessary for IE >= 10
        printWindow.focus() // necessary for IE >= 10*/
        printWindow.print()
        printWindow.close()
      })
    },
    setDimensions(data) {
      const { zoom } = data[0].target.style
      this.maxHeight = `${(82 * 100) / parseFloat(zoom)}vh`
    },
    isLTE(connectionMode) {
      const connectionModeArr = connectionMode || []
      return connectionModeArr.includes('eth1')
    },
    isWifi(connectionMode) {
      const connectionModeArr = connectionMode || []
      return connectionModeArr.includes('wlan0')
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
